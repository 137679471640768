<template>
<div class="promotion">
    <SubNavBar title="Promotion" />
    <div class="container">
        <div class="item"><img src="https://d38w4tjsz5fa1a.cloudfront.net/webGamemaniaRelease/static/img/v2022/promotions/1.jpg" alt="">
            <div class="bottom" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="left">19/01/2022 - 10/02/2022</div>
                <div class="right" style="display: flex; justify-content: center; align-items: center;">JOIN NOW</div>
            </div>
            <!---->
        </div>
        <div class="item"><img src="https://d38w4tjsz5fa1a.cloudfront.net/webGamemaniaRelease/static/img/v2021/promotions/3.png" alt="">
            <div class="bottom" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="left">09/06/2020 - 08/10/2022</div>
                <div class="right" style="display: flex; justify-content: center; align-items: center;">DOWNLOAD</div>
            </div>
            <!---->
        </div>
        <div class="item"><img src="https://d38w4tjsz5fa1a.cloudfront.net/webGamemaniaRelease/static/img/v2021/promotions/4.png" alt="">
            <div class="bottom" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="left">09/06/2020 - 08/09/2022</div>
                <div class="right" style="display: flex; justify-content: center; align-items: center;">LEARN MORE</div>
            </div>
            <!---->
        </div>
        <div class="item"><img src="https://d38w4tjsz5fa1a.cloudfront.net/webGamemaniaRelease/static/img/v2021/promotions/6_1.png" alt="">
            <div class="bottom" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="left">29/10/2021 - 10/11/2021</div>
                <div class="right" style="display: none; justify-content: center; align-items: center;">LEARN MORE</div>
            </div>
            <div class="finished" style="display: flex; justify-content: center; align-items: center;">FINISHED</div>
        </div>
        <div class="item"><img src="https://d38w4tjsz5fa1a.cloudfront.net/webGamemaniaRelease/static/img/v2021/promotions/5.png" alt="">
            <div class="bottom" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="left">01/08/2021 - 07/08/2021</div>
                <div class="right" style="display: none; justify-content: center; align-items: center;">LEARN MORE</div>
            </div>
            <div class="finished" style="display: flex; justify-content: center; align-items: center;">FINISHED</div>
        </div>
        <div class="item"><img src="https://d38w4tjsz5fa1a.cloudfront.net/webGamemaniaRelease/static/img/v2021/promotions/1.png" alt="">
            <div class="bottom" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="left">09/06/2020 - 07/08/2020</div>
                <div class="right" style="display: none; justify-content: center; align-items: center;">GET NOW</div>
            </div>
            <div class="finished" style="display: flex; justify-content: center; align-items: center;">FINISHED</div>
        </div>
        <div class="item"><img src="https://d38w4tjsz5fa1a.cloudfront.net/webGamemaniaRelease/static/img/v2021/promotions/2.png" alt="">
            <div class="bottom" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="left">21/08/2020 - 24/08/2020</div>
                <div class="right" style="display: none; justify-content: center; align-items: center;">LEARN MORE</div>
            </div>
            <div class="finished" style="display: flex; justify-content: center; align-items: center;">FINISHED</div>
        </div>
    </div>
</div>
</template>

<script>
import SubNavBar from '@/components/SubNavBar.vue'
export default {
    name: 'Promotion',
    components: {
        SubNavBar
    }
};
</script>

<style lang="scss" scoped>
.promotion {
    width: 10rem;
    height: 100%;
    background-color: #17191c;

    .container {
        position: absolute;
        left: 0;
        right: 0;
        top: 1.25rem;
        bottom: 1.38888889rem;
        overflow: auto;
        padding: 0 0.27777778rem;

        .item {
            position: relative;
            overflow: hidden;
            height: 3.05555556rem;
            border-radius: 0.08333333rem;
            margin: 0.27777778rem 0 0;

            >img {
                width: 100%;
                height: 3.05555556rem;
            }

            .bottom {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 0.75rem;
                font-size: .30555556rem;
                background-color: rgba(0, 0, 0, .5);
                box-sizing: border-box;
                padding: 0 0.11111111rem 0 0.25rem;
                color: #fff;

                .right {
                    width: fit-content;
                    height: 0.61111111rem;
                    padding: 0 0.16666667rem;
                    color: #fff;
                    font-size: .48148148rem;
                    font-weight: 900;
                    background: #d8d8d8 linear-gradient(90deg, #e601fe, #7902ff);
                    font-size: .31481481rem;
                    border-radius: 0.30555556rem;
                    font-weight: 600;
                }
            }

            .finished {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0,0,0,.6);
                color: #fff;
                font-size: .5rem;
                font-weight: 700;
            }

            

        }

    }

}
</style>
